import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as productWarrantyStyles from "./ProductWarranty.module.scss"

const RockyWarranty = ({ pageContext, location }) => {
  const pageTitle = "Rocky Product Warranty"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container-alt inner-y-padding">
        <h2>Rocky Product Warranty</h2>
        <p>
          <small>
            <em>Rocky Product Warranty for U.S. products only</em>
          </small>
        </p>
        <ul className={productWarrantyStyles.warrantyBullets}>
          <li>
            All ROCKY® Products have a 1-year, limited warranty in normal use
            against any manufacturing flaw(s), defective materials and
            workmanship.
          </li>
          <li>
            This warranty also covers Gore-Tex bootie liners against leakage for
            1 year. All boots with Rocky, waterproof constructions are
            guaranteed waterproof for 6 months from date of purchase.
          </li>
          <li>
            The Rocky warranty does NOT cover damage due to holes worn in toes,
            burn marks, punctures, cuts, and abrasions from outside causes or
            misuse and/or abuse by the wearer.
          </li>
          <li>
            The warranty may be void if the user applies any materials not
            recommended, such as polishes, oils, or other chemicals that result
            in damage to the materials of the footwear.
          </li>
          <li>
            All products must be returned and inspected before FULL CREDIT is
            issued or a REPLACEMENT of the same or equal value is given. ROCKY
            DOES NOT PRO RATE OR OFFER PARTIAL CREDIT. We will replace all Rocky
            products at our discretion.
          </li>
          <li>
            We expect our Retailers to handle and accept returns in accordance
            with the Rocky warranty and return policy. If footwear was purchased
            directly from Rocky Brands please contact 1-866-442-4908 to obtain
            your return authorization number.
          </li>
          <li>
            If your boots were not purchased from either Rocky Brands or one of
            our Authorized Dealers this voids the warranty.
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default RockyWarranty
